<!--  -->
<template>
  <div class="index">
    <van-tabs :sticky="true">
      <van-tab :title="item.title" v-for="item in tabsList" :key="item.id">
        <div class="headle_search">
          <div class="search">
            <div class="search_left" @click="toSearch">
              <div class="icon icon-sousuo"></div>
              <div>搜索</div>
            </div>
            <div class="search_rigth">
              <span class="icon icon-add" @click="onAddWorkOrder"></span>
            </div>
          </div>
        </div>
        <workList :item="item" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import workList from "./components/workList/index.vue";
import { ChangePageTitle } from "../../common/js/upTitle";
export default {
  components: {
    workList,
  },
  data() {
    //这里存放数据
    return {
      keywords: "",
      keyword: "",
      tabsList: [
        {
          id: 4,
          title: "全部",
          isAcitve: true,
        },
        {
          id: 0,
          title: "未开始",
          isAcitve: false,
        },
        {
          id: 1,
          title: "执行中",
          isAcitve: false,
        },
        {
          id: 2,
          title: "已结束",
          isAcitve: false,
        },
        {
          id: 3,
          title: "已取消",
          isAcitve: false,
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onAddWorkOrder() {
      this.$router.push({
        name: "/createWork",
      });
    },
    toSearch(){
      this.$router.push('/search')
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    ChangePageTitle("首页");
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='less' scoped>
.index {
  width: 100%;

  .headle_search {
    width: 100%;
    height: 118px;
    background-color: #f5f7f9;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 1000;
    position: fixed;
    left: 0;
    top: 75.08px;
    box-sizing: border-box;
    .search {
      width: 95%;
      margin: auto;
      display: flex;
      align-items: center;
      overflow: hidden;
      .search_left {
        flex: 1;
        font-size: 30px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 10px 0 10px 20px;
        .icon {
          font-size: 45px;
          margin-right: 20px;
        }
      }
      .search_rigth {
        width: 50px;
        .icon {
          text-align: center;
          line-height: 2.2;
          font-size: 40px;
          margin-left: 10px;
          color: blue;
        }
      }
    }
  }
  /deep/ .van-tabs__line {
    display: none;
  }
  /deep/ .van-tab--active {
    color: blue;
  }
  .work_search {
    position: fixed;
    left: 0;
    top: 75.08px;
    z-index: 100;
  }
  .list {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}
</style>
